<template>
  <div id="right-container" :style="!showNav?'width:100%':''">
    <!--顶部栏-->
    <div id="top-info-container" class="flex flex-between">
      <div class="left flex flex-start">
        <div class="toggle-nav">
          <i class="el-icon-s-fold show" v-if="showNav" @click="clickShowNavToggle"></i>
          <i class="el-icon-s-unfold hide" v-if="!showNav" @click="clickShowNavToggle"></i>
        </div>
        <div class="page-name" v-if="!currentNav.breadList">{{ currentNav.name }}</div>
        <div class="page-name" v-if="currentNav.breadList">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item v-for="item in currentNav.breadList">
              <span :style="item.path?'cursor:pointer':''" @click="clickBread(item)">{{ item.name }}</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <div class="right flex flex-start">
        <span class="name">{{ userInfo.username }}</span>
        <span class="avatar">{{userInfo.username.substring(0,1)}}</span>
        <div class="login-out flex flex-dr flex-center" @click="clickLoginOutBtn">
          <img src="@/assets/icon/login-out.png" alt="退出登录" class="normal">
          <img src="@/assets/icon/login-out-hover.png" alt="退出登录" class="hover">
        </div>
      </div>
    </div>
    <!--每个页面-->
    <div id="page-container">
      <router-view/>
      <!--底部栏-->
      <div id="bottom-container" class="flex flex-center">
      <span class="flex flex-start cursor-pointer" @click="clickFeedbackBtn">问题反馈<img src="@/assets/icon/feedback.png"
                                                                                      alt=""></span>
        <span class="flex flex-start cursor-pointer" @click="e=>clickContactUsBtn(e)">联系我们<img src="@/assets/icon/qq.png"
                                                                                               alt=""></span>
        <span>技术支持：成都智云鸿道信息技术有限公司</span>
        <span>{{ backUpNumber }}</span>
      </div>
    </div>
    <!--弹窗-问题反馈-->
    <el-dialog
        title="问题反馈"
        :visible.sync="feedbackShow"
        :close-on-click-modal="false"
        width="60%"
        center
        v-el-drag-dialog>
      <div class="dialog-container">
        <el-input type="textarea" v-model="feedbackContent" rows="20" placeholder="在使用过程遇见的任何问题和建议，我们将尽快响应！"
                  maxlength="1000" :show-word-limit="feedbackContent.length>900"></el-input>
      </div>
      <div slot="footer">
        <el-button type="default" @click="feedbackShow=false">取消</el-button>
        <el-button type="primary" @click="clickFeedbackSendBtn">提交反馈</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from "vuex";
import elDragDialog from '@/directive/el-drag-dialog'
import {msg_confirm, msg_confirm_choose, msg_err, msg_success} from "@/utils/ele_component";
import {FeedbackModel} from "@/model/erp/FeedbackModel";
import {ConfigModel} from "@/model/erp/ConfigModel";
import clip from '@/utils/clipboard';
import {MessageBox} from "element-ui";
import {LoginModel} from "@/model/exp/LoginModel";
import {RouterModel} from "@/model/RouterModel";
import store from "@/store";
import {CONFIG_NAME_EXP, PLATFORM_ID} from "@/model/ConfigModel";

export default {
  name: "rightContainer",
  directives: {
    elDragDialog
  },
  components: {},
  computed: {
    ...mapState({
      currentNav: state => state.currentNav,
      userInfo: state => state.user,
      showNav: state => state.showNav
    })
  },
  data() {
    return {
      loginPageConfig: {},
      feedbackShow: false,
      feedbackContent: "",
      contact_qq: "",
      backUpNumber: "",
    }
  },
  async mounted() {
    // 是否显示导航
    let showNav = sessionStorage.getItem("showNav")
    if (showNav === null) {
      console.log(showNav)
      showNav = true
    }
    if (showNav === "false") {
      showNav = false
    }
    store.commit('SET_SHOW_NAV', showNav)

    this.getLoginConfig()
    // 获取联系QQ号
    this.contact_qq = await ConfigModel.getConfig(CONFIG_NAME_EXP, "contact_qq")
    // 获取备案号
    this.backUpNumber = await ConfigModel.getConfig(CONFIG_NAME_EXP, "backUpNumber")
  },
  methods: {
    // 点击退出登录按钮=
    async clickLoginOutBtn(){
      if(await msg_confirm_choose("确定要退出登录吗？","退出登录","取消","退出登录")==="right"){
        LoginModel.loginOut()
      }
    },
    // 点击导航显示隐藏图标
    clickShowNavToggle() {
      let showNav = true
      if (this.showNav) {
        showNav = false
      } else {
        showNav = true
      }
      sessionStorage.setItem("showNav", showNav + "")
      store.commit("SET_SHOW_NAV", showNav)
    },
    // 点击面包导航
    clickBread(item) {
      console.log(item);
      if (item.path) {
        RouterModel.routerPush(this, item.path, item.name, "", item.index)
      }
    },
    // 获取登录配置
    async getLoginConfig() {
      let schoolLoginPageConfig = sessionStorage.getItem("exp_schoolLoginPageConfig")
      if (schoolLoginPageConfig) {
        this.loginPageConfig = JSON.parse(schoolLoginPageConfig)
      } else {

      }
    },
    // 点击下拉菜单
    clickDownBtn(command) {
      switch (command) {
        case "loginOut":// 退出登录
          LoginModel.loginOut()
          break
      }
    },
    // 点击问题反馈按钮
    async clickFeedbackBtn() {
      this.feedbackShow = true
    },
    // 点击问题反馈-提交按钮
    async clickFeedbackSendBtn() {
      if (!this.feedbackContent) {
        msg_err("请先填写反馈内容！")
        return
      }
      if (await FeedbackModel.addOrEdit(PLATFORM_ID, {
        creatorId: this.userInfo.unionuserid,
        creatorInfo: {
          roleName: this.userInfo.rolename === 'teacher' ? '老师' : '学生',
          account: this.userInfo.account
        },
        content: this.feedbackContent,
        extraInfo: {}
      })) {
        msg_success("提交成功，感谢您的反馈！")
        this.feedbackShow = false
        this.feedbackContent = ""
      }
    },
    // 点击联系我们按钮
    async clickContactUsBtn(e) {
      console.log(e)
      clip(this.contact_qq, e);
      const url = `tencent://message/?uin=${this.contact_qq}&Site=Sambow&Menu=yes`
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      document.body.appendChild(link)
      link.click()
      link.remove()
      await msg_confirm("如果QQ自动打开失败，您也可以使用自动复制的QQ号：\n" + this.contact_qq + " 与我们联系！", 'QQ号复制成功')
    }
  }
}
</script>

<style scoped lang="less">


// 顶部栏
#top-info-container {
  height: 60px;
  line-height: 60px;
  padding: 0px 10px;
  background-color: #fff;

  .left {
    .toggle-nav {
      margin-right: 10px;

      i {
        font-size: 20px;
        color: #85878b;
        cursor: pointer;
      }
    }

    .page-name {
      font-size: 15px;
      color: #333;
    }
  }

  .right {
    .name {
      margin-right: 10px;
      font-size: 15px;
      color: #666;
    }

   .avatar{
     width: 32px;
     height: 32px;
     text-align: center;
     line-height: 32px;
     border-radius: 16px;
     font-size: 14px;
     font-weight: 400;
     background-color: #1890ff;
     color: #FFFFFF;
   }

    div.login-out{
      width: 60px;
      height: 60px;
      line-height: 60px;
      margin-left: 10px;
      background-color: #fff;
      .text{
        visibility: hidden;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #FFFFFF;
      }
      img{
        width: 40px;
        height: 40px;
        cursor: pointer;
        display: block;
      }
      img.hover{
        display: none;
      }
      &:hover{
        background-color: #1890ff;
        .text{
          visibility: visible;
        }
        img.normal{
          display: none;
        }
        img.hover{
          display: block;
        }
      }
    }
  }
}

// 每个页面
#page-container {
  padding: 10px;
  >.page-container{
    background-color: #fff;
    min-height:  calc(100vh - 130px);
  }
}

// 底部栏
#bottom-container {
  margin-top: 26px;
  padding-bottom: 7px;
  font-size: 14px;
  color: #707070;
  max-width: 100%;
  min-width: 600px;

  span {
    margin-right: 20px;
  }

  img {
    width: 12px;
    height: 14px;
    margin-left: 3px;
    vertical-align: middle;
  }
}
</style>
