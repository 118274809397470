import { addOrEdit} from "@/api/erp/FeedbackApi";

/**
 * 反馈model
 */
class FeedbackModel {
  // 新增或修改
  static async addOrEdit(platformId, entity) {
    let [data] = await addOrEdit(platformId, entity);
    return data;
  }
}

export {FeedbackModel}
