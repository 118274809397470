<template>
  <div id="main-layout" class="flex flex-start">
    <left-container v-show="showNav"></left-container>
    <right-container>
      <router-view/>
    </right-container>
  </div>
</template>

<script>
import LeftContainer from "./leftContainer"
import RightContainer from "./rightContainer"
import {mapState} from "vuex";

export default {
  name: "layout",
  components: {LeftContainer, RightContainer},
  computed: {
    ...mapState({
      showNav: state => state.showNav
    })
  },
}
</script>

<style scoped lang="less">
#main-layout {
  align-items: flex-start;
}

#left-container {
  width: 260px;
  position: relative;
  overflow: hidden;
  background-color: #fff;
}

#right-container {
  width: calc(100% - 260px);
  background-color: #EBEFF2;
}
</style>