<template>
  <div id="left-container">
    <div class="show-nav-container" v-if="showNav">
      <!--画4分之1弧形-->
      <div class="bg-circle"></div>
      <!--顶部title和logo-->
      <div class="top-box">
        <div class="title" v-if="loginPageConfig.schoolid!=='233994425120854016'">
          <div>思政课虚拟仿真</div>
          <div>体验教学中心</div>
        </div>
        <div class="title" v-if="loginPageConfig.schoolid==='233994425120854016'">
          <div>军事领导虚拟仿真实验</div>
          <div></div>
        </div>
        <img :src="loginPageConfig.icon" alt="" class="logo">
      </div>
      <!--导航条-->
      <div class="nav-bar">
        <!--学生角色路由-->
        <template v-if="userInfo.rolename==='student'">
          <div class="li">
            <div class="title flex flex-between" @click="NavMethods().clickToggleSubNav(0)">
              <div>
                <img class="icon" src="@/assets/icon/pc.png"/>
                <span class="text">我的实验</span>
              </div>
              <i :class="subNavShowStatus[0]?'toggle-sub el-icon-arrow-down':'toggle-sub  el-icon-arrow-up'"></i>
            </div>
            <el-collapse-transition>
              <div class="sub-nav" v-show="subNavShowStatus[0]">
                <div :class="currentNavIndex==='0-0'?'sub-li on':'sub-li'"
                     @click="NavMethods().clickNav('/student/expList?status=1','我的实验-进行中',{},'0-0')">进行中
                </div>
                <div :class="currentNavIndex==='0-1'?'sub-li on':'sub-li'"
                     @click="NavMethods().clickNav('/student/expList?status=0','我的实验-未开始',{},'0-1')">未开始
                </div>
                <div :class="currentNavIndex==='0-2'?'sub-li on':'sub-li'"
                     @click="NavMethods().clickNav('/student/expList?status=2','我的实验-已结束',{},'0-2')">已结束
                </div>
              </div>
            </el-collapse-transition>
          </div>
          <!--学生角色单级路由-->
          <template v-for="(item,index) in RouteList['student']" v-if="userInfo.rolename==='student'">
            <div :class="currentNavIndex===item.index?'li on':'li'"
                 @click="NavMethods().clickNav(item.path,item.name,item.info,item.index)">
              <div class="title flex flex-between">
                <div class="flex flex-start">
                  <img class="icon" :src="item.icon"/>
                  <span class="text">{{ item.name }}</span>
                </div>
              </div>
            </div>
          </template>
        </template>
        <!--教师角色路由-->
        <template v-for="(item,index) in RouteList['teacher']" v-if="userInfo.rolename==='teacher'">
          <template v-if="
          (
              (item.index==='8'&&dashboardSchoolList.indexOf(userInfo.schoolId)!==-1)||
              (item.index==='9'&&expWebConfigList.indexOf(userInfo.schoolId)!==-1)||
              (item.index==='10'&&examSchoolList.indexOf(userInfo.schoolId)!==-1)
          )
          ||(item.index!=='8'&&item.index!=='9'&&item.index!=='10')">
            <div :class="currentNavIndex===item.index?'li on':'li'"
                 @click="NavMethods().clickNav(item.path,item.name,item.info,item.index)">
              <div class="title flex flex-between">
                <div class="flex flex-start">
                  <img class="icon" :src="item.icon"/>
                  <span class="text">{{ item.name }}</span>
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-for="(item,index) in RouteList['other_cddx']" v-if="userInfo.rolename==='teacher'&&userInfo.schoolId==='457463292223295488'">
          <div :class="currentNavIndex===item.index?'li on':'li'"
               @click="NavMethods().clickNav(item.path,item.name,item.info,item.index)">
            <div class="title flex flex-between">
              <div class="flex flex-start">
                <img class="icon" :src="item.icon"/>
                <span class="text">{{ item.name }}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="hide-nav-container" v-if="!showNav">

    </div>
  </div>
</template>

<script>
import {RouterModel} from "@/model/RouterModel";
import RouteList from "@/router/routeList"
import {mapState} from 'vuex'
import {LoginConfigModel} from "@/model/exp/LoginConfigModel";
import {dashboardSchoolList, dashboardV2SchoolList} from "@/config/DashboardConfig";
import {expWebConfigList} from "@/config/ExpWebConfig";
import {examSchoolList} from "@/config/ExamConfig";

export default {
  name: "leftContainer",
  computed: {
    ...mapState({
      currentNavIndex: state => state.currentNav.index,
      userInfo: state => state.user,
      showNav: state => state.showNav
    })
  },
  data() {
    return {
      loginPageConfig: {},
      RouteList: RouteList,
      subNavShowStatus: [
        true
      ],
      expWebConfigList: expWebConfigList,
      dashboardSchoolList: dashboardSchoolList,
      dashboardV2SchoolList: dashboardV2SchoolList,
      examSchoolList:examSchoolList,
    }
  },
  mounted() {
    this.getLoginConfig()
  },
  methods: {
    // 获取登录配置
    async getLoginConfig() {
      let schoolLoginPageConfig = sessionStorage.getItem("exp_schoolLoginPageConfig")
      if (schoolLoginPageConfig.asSchoolLogin) {
        this.loginPageConfig = JSON.parse(schoolLoginPageConfig)
      } else {
        this.loginPageConfig = await LoginConfigModel.getLoginConfig(this.userInfo.schoolId)
      }
      console.log(this.loginPageConfig)
    },
    // 导航Methods
    NavMethods() {
      let $this = this;
      return {
        // 点击切换子菜单隐藏显示按钮
        clickToggleSubNav(index) {
          $this.$set($this.subNavShowStatus, index, !$this.subNavShowStatus[index])
        },
        // 点击某个菜单
        clickNav(path, name, info, index) {
          RouterModel.routerPush($this, path, name, info, index)
        }
      }
    }
  }
}
</script>

<style scoped lang="less">

.bg-circle {
  position: absolute;
  background: #2D7BC9;
  width: 520px;
  height: 520px;
  border-radius: 260px;
  left: -130px;
  top: -370px;
}

.top-box {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  color: #fff;
  height: 150px;

  .title {
    padding-top: 45px;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;

    div {
      text-align: center;
    }
  }

  .logo {
    display: block;
    width: 90px;
    height: 90px;
    border-radius: 45px;
    margin: 0 auto;
    margin-top: 5px;
  }
}

.nav-bar {
  margin-top: 250px;
  color: #333;


  .li {
    border-right: 2px solid #fff;

    &.on {
      color: #1890FF;
      background-color: #E6F7FF;
      border-right: 2px solid #1890FF;
    }

    .title {
      padding: 13px 30px;
      padding-left: 70px;
      cursor: pointer;


      img {
        width: 16px;
        height: 15px;
        display: inline-block;
        margin-right: 10px;
      }

      .text {
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.65);
      }

      i.toggle-sub {
        cursor: pointer;
      }
    }

    .sub-nav {
      .sub-li {
        cursor: pointer;
        padding: 10px 0px 10px 95px;
        font-size: 14px;
        border-right: 2px solid #fff;
        color: rgba(0, 0, 0, 0.65);
      }

      .sub-li.on, .sub-li:hover {
        color: #1890FF;
        background-color: #E6F7FF;
        border-right: 2px solid #1890FF;
      }
    }
  }
}
</style>
